import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const FetchStaticFile = ({ file, fromPythonServer = false }) => {
	const [ content, setContent ] = useState(null);
	const [ isImage, setIsImage ] = useState(false);
	const { '*': wildcardParams } = useParams();
	const url = fromPythonServer ?
		`${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_PYTHON_SERVER_URI }/${ file ?? wildcardParams }`
		: `${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/public/${ file ?? wildcardParams }`;
	const R404 = `${ process.env.REACT_APP_PROTOCOL }://${ process.env.REACT_APP_SERVER_URI }/public/404.html`;

	const fileExtension = url.split('.').pop();

	useEffect(() => {
		if ([ "jpg", "jpeg", "png", "gif" ].includes(fileExtension)) {
			setIsImage(true);
			setContent(url);
		} else {
			fetch(url)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.text();
				})
				.then((data) => setContent(data))
				.catch(() => {
					// If file is not found, fetch the 404.html
					fetch(R404)
						.then((response) => response.text())
						.then((data) => setContent(data))
						.catch(() => setContent(`The server is not responding.`));
				});
		}
	}, [ url, fileExtension ]);

	if (isImage) {
		return <img src={ content } alt="Static File" />;
	}
	// return <div dangerouslySetInnerHTML={ { __html: content } } />;
	return (
		<iframe
			src={ url }
			title="Static File"
			className="overflow-hidden border-none m-auto"
			style={ { width: "99vw", height: "90vh" } }
		/>
	);
};

export default FetchStaticFile;