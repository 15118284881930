import { useParams } from "react-router-dom";
import FetchStaticFile from "../../modules/html/components/FetchStaticFile";

export function CoverageMap () {
	const { make } = useParams();

	return (
		<>
			<FetchStaticFile
				file={ `coveragemaps/${ make }_map_heat.html` }
				fromPythonServer={ true }
			/>
		</>
	)
};

export default CoverageMap;